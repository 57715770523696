import type { Enums } from "~/types/supabase";

export type ProtectedSection =
  | "calendar"
  | "crews"
  | "equipment"
  | "examples"
  | "inspections"
  | "inspections_quick_create"
  | "jobsites"
  | "map"
  | "profile_attendance_exception"
  | "profile_checklist"
  | "profile_pto"
  | "profile_switcher"
  | "tasks"
  | "user_profiles"
  | "user_roles"
  | "workdays"
  | "workdays_assign"
  | "workorders"
  | "workorders_quick_create"
  | "workorders_scheduler"
  | "workorder_photos";

/**
 * Composable defining a basic security manager for the application.
 *
 * This composable augments and emulates the `authorize()` function in Supabase to perform basic ACL checks in-app,
 * avoiding a need to make repetitive network calls to use the function.
 */
export default () => {
  // Initialize the state reference immediately
  const userRole = useState<Enums<"app_role"> | null>(
    "merl_user_role",
    () => null
  );

  function isAdmin() {
    return userRole.value === "admin";
  }

  function canCreate(section: ProtectedSection) {
    switch (section) {
      // Only admins and directors can create these items
      case "crews":
      case "equipment":
      case "jobsites":
      case "workdays":
        return ["admin", "director"].includes(userRole.value);

      // All roles can create these items
      case "inspections":
      case "profile_attendance_exception":
      case "profile_pto":
      case "workorders":
        return true;

      default:
        // Default to no access if a section is not supported
        return false;
    }
  }

  function canDelete(section: ProtectedSection) {
    switch (section) {
      // Only admins and directors can remove these items
      case "crews":
      case "equipment":
      case "inspections":
      case "profile_pto":
      case "tasks":
      case "user_profiles":
      case "workorders":
        return isAdmin();
      case "workdays":
      case "workorder_photos":
        return ["admin", "director"].includes(userRole.value);

      case "profile_attendance_exception":
        // All roles can remove attendance exceptions except directors
        return "director" !== userRole.value;

      default:
        // Default to no access if a section is not supported
        return false;
    }
  }

  function canEdit(section: ProtectedSection) {
    switch (section) {
      // Only admins can edit these items
      case "profile_checklist":
      case "user_profiles":
      case "user_roles":
        return isAdmin();
      case "crews":
      case "equipment":
      case "inspections":
      case "workdays_assign":
      case "workorders_scheduler":
        return ["admin", "director"].includes(userRole.value);

      case "jobsites":
      case "tasks":
        return ["admin", "director", "foreman"].includes(userRole.value);

      case "workorders":
        return ["admin", "director", "shop"].includes(userRole.value);

      case "workdays":
        return userRole.value !== null && userRole.value !== "shop";

      default:
        // Default to no access if a section is not supported
        return false;
    }
  }

  function canView(section: ProtectedSection) {
    switch (section) {
      // Only admins and directors can view these sections
      case "crews":
      case "examples":
      case "profile_switcher":
      case "user_profiles":
      case "profile_attendance_exception":
      case "profile_checklist":
      case "profile_pto":
      case "tasks":
      case "user_roles":
      case "workdays":
      case "workdays_assign":
      case "workorders":
      case "workorder_photos":
      case "inspections": // Users can view their own inspection, this is here for the sidebar nav showing "ALL" inspections
        return ["admin", "director"].includes(userRole.value);

      case "equipment":
      case "map":
      case "workorders_scheduler":
        // In the database, field and office needs to be able to see equipment since it's passed in on workdays. But in the UI they shouldn't see the full equipment list.
        return !["field", "office"].includes(userRole.value);

      case "jobsites":
      case "calendar":
        return ["admin", "director", "foreman"].includes(userRole.value);

      case "workorders_quick_create":
        // Only field workers can view the quick create work orders and inspections section
        return ["field", "office"].includes(userRole.value);

      case "inspections_quick_create":
        // admins don't need the quick create button
        return ["field", "office", "foreman"].includes(userRole.value);
      default:
        // Default to no access if a section is not supported
        return false;
    }
  }

  return { canCreate, canDelete, canEdit, canView };
};
